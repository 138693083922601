import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import SectionHeader from '../../components/SectionHeader'
import SectionTextTwoColumns from '../../components/SectionTextTwoColumns'
import Layout from '../../layout/Layout'

export default function Home() {
  const { headerBg } = useStaticQuery(
    graphql`
      query {
        headerBg: file(
          relativePath: {
            eq: "nasze-uslugi/transport-w-temperaturze-kontrolowanej.jpg"
          }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    `
  )

  return (
    <Layout
      metaTitle="Transport w temperaturze kontrolowanej"
      metaDescription="Specyfika niektórych towarów wymaga tego, aby były przewożone w szczególnych warunkach. Transport w temperaturze kontrolowanej to jedna z usług, jakie możemy zaproponować Klientom z różnych branż, np. spożywczej, kosmetycznej."
      slug="/uslugi/transport-w-temperaturze-kontrolowanej"
    >
      <SectionHeader
        smallTitle="Usługi"
        title={
          <>
            Transport <br />
            w temperaturze <br />
            kontrolowanej
          </>
        }
        titleBorder="white"
        lead="Specyfika niektórych towarów wymaga tego, aby były przewożone w szczególnych warunkach. Transport w temperaturze kontrolowanej to jedna z usług, jakie możemy zaproponować Klientom z&nbsp;różnych branż, np. spożywczej, kosmetycznej."
        content={
          <>
            Dysponujemy odpowiednią do tego flotą pojazdów oraz spełniamy
            określone przepisami wymogi, które muszą być zachowane przez cały
            czas przewozu. Ze względu na to, że dostarczany towar łatwo się
            psuje lub zmienia swój skład chemiczny przy najmniejszych wahaniach
            temperatur, jest to konieczność. Wykonujemy zlecenia na transport
            chłodniczy międzynarodowy i&nbsp;krajowy. Kierowcy kontrolują
            temperaturę w&nbsp;skrzyniach ładunkowych w&nbsp;trakcie wykonywania
            przewozu dzięki specjalnym urządzeniom rejestrującym jej wysokość.
            Są to tzw. termografy.
          </>
        }
        buttonType="bordered"
        scroll="#czytaj-wiecej"
        halfBg
        bgImage={getImage(headerBg)}
      />
      <SectionTextTwoColumns
        id="czytaj-wiecej"
        smallTitle="Transport w temperaturze kontrolowanej"
        title={
          <>
            Jakie towary przewozi <br />
            się w temperaturze <br />
            kontrolowanej?
          </>
        }
        lead={
          <>
            W zależności od rodzaju produktów dopasowujemy odpowiedni transport
            chłodniczy.
          </>
        }
      >
        <p className="condensed">
          <b>
            Do towarów wymagających szczególnych warunków transportowych zalicza
            się przede wszystkim:
          </b>
          <ul>
            <li>
              towary żywnościowe pochodzenia zwierzęcego i&nbsp;roślinnego
            </li>
            <li>wyroby i półprodukty mięsne</li>
            <li>ryby i owoce morza</li>
            <li>wyroby cukiernicze</li>
            <li>napoje alkoholowe i bezalkoholowe</li>
            <li>produkty medyczne</li>
            <li>półprodukty farmaceutyczne</li>
            <li>leki</li>
            <li>kosmetyki</li>
            <li>produkty chemiczne</li>
            <li>elektronikę</li>
            <li>niektóre materiały budowlane</li>
            <li>odczynniki chemiczne</li>
            <li>organiczne środki rolnicze</li>
            <li>rośliny i grzyby</li>
            <li>ładunki niebezpieczne.</li>
          </ul>
        </p>
      </SectionTextTwoColumns>
      <SectionTextTwoColumns
        smallTitle="Transport w temperaturze kontrolowanej"
        title={
          <>
            W jaki sposób odbywa&nbsp;się transport w&nbsp;temperaturze
            kontrolowanej?
          </>
        }
        lead={
          <>
            Do realizacji usługi krajowego lub międzynarodowego transportu
            chłodniczego wykorzystujemy specjalistyczne nadwozia izotermiczne
            z&nbsp;agregatem naczepowym lub używamy kontenerów chłodniczych,
            tzw. reeferów. Te ostatnie najczęściej stosowane są
            w&nbsp;przewozach m.in. owoców, warzyw, ryb i&nbsp;mięsa.
          </>
        }
      >
        <p>
          Realizacja zleceń polega na spełnianiu rygorystycznych wymogów
          bezpieczeństwa, do jakich zalicza się m.in. monitorowanie temperatury
          towaru, która powinna być jednakowa przez cały czas transportu. Służą
          do tego specjalne urządzenia elektroniczne. Niektóre produkty, oprócz
          odpowiedniej ilości stopni Celsjusza, wymagają także zapewnienia
          takich parametrów jak właściwa wentylacja i&nbsp;wilgoć. Dotyczy to
          zwłaszcza świeżych owoców i&nbsp;warzyw.
        </p>
        <p>
          Transport chłodniczy odbywa się poprzez utrzymanie temperatury
          w&nbsp;warunkach chłodniczych lub grzewczych. Wybór konkretnej opcji
          uzależniony jest od specyfiki i&nbsp;właściwości towaru. Pojazdy
          chłodnicze są standardowo przystosowane do widełek temperaturowych: od
          -25 stopni&nbsp;C (jeśli zachodzi konieczność schłodzenia produktu na
          czas podróży) do +25 stopni&nbsp;C (w&nbsp;przypadku, gdy ładunek
          wymaga podgrzania i&nbsp;utrzymywania w&nbsp;konkretnej temperaturze).
        </p>
      </SectionTextTwoColumns>
      <SectionTextTwoColumns
        smallTitle="Transport w temperaturze kontrolowanej"
        title={
          <>
            Niezbędna <br />
            dokumentacja <br />
          </>
        }
        lead={
          <>
            Przy każdym rodzaju transportu wymagana jest odpowiednia
            dokumentacja przewozowa, a&nbsp;w&nbsp;przypadku specjalistycznych,
            do których zalicza się chłodniczy, jej zakres jest dodatkowo
            poszerzony.
          </>
        }
      >
        <p className="condensed">
          <b>W momencie przewozu np. artykułów żywnościowych wymagane są:</b>
          <ul>
            <li>świadectwo jakości towarów żywnościowych</li>
            <li>świadectwo stanu technicznego chłodni – certyfikat ATP</li>
            <li>
              decyzja dopuszczająca środek transportu do przewozu żywności
            </li>
            <li>
              orzeczenie zdolności kierowcy w zakresie przewozu żywności –
              orzeczenie dla celów sanitarnoepidemiologicznych
            </li>
            <li>karta badania kierowcy na nosicielstwo pałeczek S-S</li>
            <li>książeczka zdrowia kierowcy</li>
            <li>książka kontroli sanitarnej pojazdu</li>
            <li>świadectwo dezynfekcji pojazdu</li>
            <li>wydruk pomiaru temperaturowego.</li>
          </ul>
        </p>
        <p className="condensed">
          <b>
            Oprócz tego nasze pojazdy są znakowane na czas transportu towarów
            w&nbsp;temperaturze kontrolowanej. Oznaczeniami są m.in.:
          </b>
          <ul>
            <li>tabliczka zawierająca skrót ATP</li>
            <li>
              opis „Dopuszczony do przewozu szybko psujących się artykułów
              żywnościowych”
            </li>
            <li>numer oficjalnego dopuszczenia</li>
            <li>symbol kraju wydającego na to zgodę</li>
            <li>numer indywidualny pojazdu</li>
            <li>literowe oznaczenie ATP</li>
            <li>znak rozpoznawczy pojazdu</li>
            <li>dopuszczenia środka transportu z&nbsp;napisem „ważny do”.</li>
          </ul>
        </p>
        <p>
          Jeśli nie będziesz wiedział, jaka dokumentacja jest niezbędna do
          transportu Twoich towarów w&nbsp;temperaturze kontrolowanej, chętnie
          Ci pomożemy.
        </p>
      </SectionTextTwoColumns>
      <SectionTextTwoColumns
        smallTitle="Transport w temperaturze kontrolowanej"
        title={
          <>Bezpieczny transport chłodniczy – zapraszamy do&nbsp;współpracy!</>
        }
        lead={
          <>
            Z pewnością przewozy towarów wrażliwych wymagają odpowiedniego
            sprzętu i&nbsp;specjalistycznej wiedzy. Zapewniamy pojazdy
            wyposażone w&nbsp;sztywną zabudowę, agregaty najwyższej jakości
            i&nbsp;nowoczesny system kontrolowania temperatury oraz certyfikację
            ATP.
          </>
        }
      >
        <p>
          Od kierowców wymagamy przeszkolenia z&nbsp;obsługi pojazdów typu
          chłodnia.
        </p>
        <p>
          Świadczony przez nas krajowy i&nbsp;międzynarodowy transport
          chłodniczy zaliczany jest do dynamicznie rozwijającego się segmentu
          logistyki. Pozwala dostarczać produkty świeże, spełniające parametry
          i&nbsp;właściwości konsumpcyjne. Jednocześnie daje możliwość
          bezpiecznego przewozu ładunków niebezpiecznych, bez ryzyka ich
          uszkodzenia lub utraty.
        </p>
        <p>
          Jak pokazuje nasze doświadczenie – z&nbsp;takiej możliwości korzystają
          przedsiębiorstwa z&nbsp;wielu branż. Jesteśmy otwarci na współpracę
          i&nbsp;zapraszamy do kontaktu!
        </p>
      </SectionTextTwoColumns>
    </Layout>
  )
}
